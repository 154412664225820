import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers, deleteUser } from "../api";
import { Avatar } from "../assets";
import { setAllUserDetails } from "../context/actions/allUsersAction";
import DataTable from "./DataTable";
import { alertNULL, alertSuccess, alertDanger } from "../context/actions/alertActions";

const DBUsers = () => {
  const allUsers = useSelector((state) => state.allUsers);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!allUsers) {
      // Fetch all users if not already fetched
      getAllUsers().then((data) => {
        dispatch(setAllUserDetails(data));
      });
    }
  }, [allUsers, dispatch]);

  // Function to handle user deletion
  const handleDeleteUser = (rowData) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      deleteUser(rowData.userId)
        .then(() => {
          dispatch(alertSuccess("User Deleted"));
          setTimeout(() => {
            dispatch(alertNULL());
          }, 3000);
          // Fetch all users again after deletion
          getAllUsers().then((data) => {
            dispatch(setAllUserDetails(data));
          });
        })
        .catch((error) => {
          dispatch(alertDanger("Failed to delete user"));
          setTimeout(() => {
            dispatch(alertNULL());
          }, 3000);
        });
    }
  };

  return (
    <div className="flex items-center justify-self-center gap-4 pt-6 w-full">
      <DataTable
        columns={[
          {
            title: "Image",
            field: "photoURL",
            render: (rowData) => (
              <img
                src={rowData.photoURL ? rowData.photoURL : Avatar}
                className="w-32 h-16 object-contain rounded-md"
                alt=""
              />
            ),
          },
          {
            title: "Name",
            field: "displayName",
          },
          {
            title: "Email",
            field: "email",
          },
          {
            title: "Verified",
            field: "emailVerified",
            render: (rowData) => (
              <p
                className={`px-2 py-1 w-32 text-center text-primary rounded-md ${
                  rowData.emailVerified ? "bg-emerald-500" : "bg-red-500"
                }`}
              >
                {rowData.emailVerified ? "Verified" : "Not Verified"}
              </p>
            ),
          },
        ]}
        data={allUsers}
        title="List of Users"
        actions={[
          {
            icon: "edit",
            tooltip: "Edit Data",
            onClick: (event, rowData) => {
              alert("You want to edit " + rowData.userId);
            },
          },
          {
            icon: "delete",
            tooltip: "Delete Data",
            onClick: (event, rowData) => handleDeleteUser(rowData),
          },
        ]}
      />
    </div>
  );
};

export default DBUsers;
