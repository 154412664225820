// import {coffee} from "../assets"
export const isActiveStyles =
  " text-2xl text-red-700 font-semibold hover:text-red-700 px-4 py-2 duration-100 transition-all ease-in-out";

export const isNotActiveStyles =
  " text-xl text-textColor hover:text-red-700 duration-100 px-4 py-2 transition-all ease-in-out";

export const statuses = [
  { id: 1, title: "Drinks", category: "drinks" },
  { id: 2, title: "Deserts", category: "deserts" },
  { id: 3, title: "Cakes ", category: "Cakes" },
  { id: 4, title: "Pastries", category: "Pastries" },
  { id: 5, title: "Bread ", category: "Bread" },
  { id: 6, title: "Chinese", category: "chinese" },
  { id: 7, title: "Bread", category: "bread" },
];

export const randomData = [
  {
    id: 1,
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/craftcakes-be696.appspot.com/o/9462908_35973.svg?alt=media&token=3392370b-47c8-4eab-acf1-72159f5a44b6",
    product_name: "Coffee",
    product_category: "Drinks & cafeteria",
    product_price: "23.00",
  },
  {
    id: 2,
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/craftcakes-be696.appspot.com/o/eiliv-aceron-_47dA0d0lY4-unsplash.jpg?alt=media&token=db58c6b4-2f29-48df-9e79-bf05ad2bd229",
    product_name: "Sweet Bread",
    product_category: "Bread",
    product_price: "17.00",
  },
  {
    id: 3,
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/craftcakes-be696.appspot.com/o/sarah-elizabeth-KUNRtYp8qd8-unsplash.jpg?alt=media&token=f42f57d5-47f5-4d4c-88f3-e716d4615b38",
    product_name: "Rolls",
    product_category: "Pastries",
    product_price: "16.00",
  },
  {
    id: 4,
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/craftcakes-be696.appspot.com/o/aditya-kulkarni-DpL-I3hyjO4-unsplash.jpg?alt=media&token=8e7ed379-635d-4191-8523-7af26686e0e8",
    product_name: "Biscuit",
    product_category: "Bread",
    product_price: "22.0",
  },
  {
    id: 5,
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/craftcakes-be696.appspot.com/o/pushpak-dsilva-2UeBOL7UD34-unsplash.jpg?alt=media&token=0548b337-ee9b-4f4c-a1ed-0380a0dd8baa",
    product_name: "Brownie",
    product_category: "Cakes",
    product_price: "5.0",
  },
  {
    id: 6,
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/craftcakes-be696.appspot.com/o/american-heritage-chocolate-DoK5qEy2L60-unsplash.jpg?alt=media&token=03d3d3dc-38bf-4793-9c50-8eb2c2577e9a",
    product_name: "Cookie",
    product_category: "Cakes",
    product_price: "7.5",
  },
];
