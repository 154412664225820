import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteAProduct, getAllProducts } from "../api";
import { DataTable } from "../components";
import { alertNULL, alertSuccess, alertError } from "../context/actions/alertActions";
import { setAllProducts } from "../context/actions/productActions";

const DBItems = () => {
  const products = useSelector((state) => state.products);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!products || products.length === 0) {
      // Fetch products only if they are not already available in the Redux store
      getAllProducts()
        .then((data) => {
          dispatch(setAllProducts(data));
        })
        .catch((error) => {
          console.error("Error fetching products:", error);
          dispatch(alertError("Failed to fetch products"));
        });
    }
  }, [products, dispatch]);

  return (
    <div className="flex items-center justify-self-center gap-4 pt-6 w-full">
      <DataTable
        columns={[
          {
            title: "Image",
            field: "imageURL",
            render: (rowData) => (
              <img
                src={rowData.imageURL}
                className="w-32 h-16 object-contain rounded-md"
                alt=""
              />
            ),
          },
          {
            title: "Name",
            field: "product_name",
          },
          {
            title: "Category",
            field: "product_category",
          },
          {
            title: "Price",
            field: "product_price",
            render: (rowData) => (
              <p className="text-xl font-semibold text-textColor flex items-center justify-center ">
                <h5 className="text-lg text-red-500">R</h5>
                {parseFloat(rowData.product_price).toFixed(2)}
              </p>
            ),
          },
        ]}
        data={products}
        title="List of Products"
        actions={[
          {
            icon: "edit",
            tooltip: "Edit Data",
            onClick: (event, rowData) => {
              alert("You want to edit " + rowData.productId);
            },
          },
          {
            icon: "delete",
            tooltip: "Delete Data",
            onClick: (event, rowData) => {
              if (
                window.confirm("Are you sure, you want to perform this action?")
              ) {
                deleteAProduct(rowData.productId)
                  .then((res) => {
                    dispatch(alertSuccess("Product Deleted"));
                    setTimeout(() => {
                      dispatch(alertNULL());
                    }, 3000);
                    // Fetch all products again after deletion
                    getAllProducts()
                      .then((data) => {
                        dispatch(setAllProducts(data));
                      })
                      .catch((error) => {
                        console.error("Error fetching products after deletion:", error);
                        dispatch(alertError("Failed to fetch products after deletion"));
                      });
                  })
                  .catch((error) => {
                    console.error("Error deleting product:", error);
                    dispatch(alertError("Failed to delete product"));
                    setTimeout(() => {
                      dispatch(alertNULL());
                    }, 3000);
                  });
              }
            },
          },
        ]}
      />
    </div>
  );
};

export default DBItems;
