import { getAuth } from "firebase/auth";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, Navigate } from "react-router-dom";
import { fadeInOut } from "./animations";
import { getAllCartItems, validateUserJWTToken } from "./api";
import { Alert, MainLoader } from "./components";
import { app } from "./config/firebase.config";
import { Main, Login, Dashboard } from "./containers";
import { setCartItems } from "./context/actions/cartAction";
import { setUserDetails } from "./context/actions/userActions";

const App = () => {
  const firebaseAuth = getAuth(app);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const alert = useSelector((state) => state.alert);
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged((cred) => {
      console.log("Auth state changed:", cred);

      setIsLoading(true);

      if (cred) {
        cred.getIdToken().then((token) => {
          validateUserJWTToken(token).then((data) => {
            console.log("User data:", data);

            if (data?.email === "mrtamaroga@gmail.com" || data?.email === "stnkadimeng8001@gmail.com ") {
              if (data) {
                getAllCartItems(data.user_id).then((items) => {
                  dispatch(setCartItems(items));
                });
              }

              dispatch(setUserDetails(data));
              setIsAuthenticated(true);
            } else {
              setIsAuthenticated(false);
            }

            setIsLoading(false);
          });
        });
      } else {
        setIsLoading(false);
      }
    });

    return () => unsubscribe();
  }, [firebaseAuth, dispatch]);

  if (isLoading) {
    return (
      <motion.div
        {...fadeInOut}
        className="fixed z-50 inset-0 bg-lightOverlay backdrop-blur-md flex items-center justify-center w-full"
      >
        <MainLoader />
      </motion.div>
    );
  }

  return (
    <div className="w-screen min-h-screen h-auto flex flex-col items-center justify-center">
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard/*" element={<Dashboard />} />
        <Route path="/checkout-success" element={<Navigate to="/checkout-success" />} />
        <Route path="/user-orders" element={<Navigate to="/user-orders" />} />

        {isAuthenticated ? (
          <>
            <Route path="/dashboard/*" element={<Dashboard />} />
            <Route path="/checkout-success" element={<Navigate to="/checkout-success" />} />
            <Route path="/user-orders" element={<Navigate to="/user-orders" />} />
          </>
        ) : (
          <>
            <Route path="/dashboard/*" element={<Navigate to="/login" />} />
            <Route path="/checkout-success" element={<Navigate to="/login" />} />
            <Route path="/user-orders" element={<Navigate to="/login" />} />
          </>
        )}
      </Routes>
      {alert?.type && <Alert type={alert?.type} message={alert?.message} />}
    </div>
  );
};

export default App;
