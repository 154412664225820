import React from "react";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from "@mui/material";

const DataTable = ({ columns, data, title, actions }) => {
  // Check if data is undefined or empty
  if (!data || data.length === 0) {
    return <div>No data available</div>;
  }

  // Check if columns are defined
  if (!columns || columns.length === 0) {
    return <div>No columns defined</div>;
  }

  const defaultMaterialTheme = createTheme();

  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <MaterialTable
        columns={columns}
        data={data}
        title={title}
        actions={actions}
      />
    </ThemeProvider>
  );
};

export default DataTable;
